<template>
  <v-container fluid>
    <v-form ref="form">
      <v-sheet rounded class="pa-4" id="form-driver">
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="transportTypesId">
              {{$_strings.transport.INPUT_LABEL_TYPE_TRANSPORT}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <common-auto-complete-items
              id="transportTypesId"
              outlined
              dense
              type="transportTypes"
              item-value="id"
              item-text="name"
              searchName="name"
              :disabled="isEditing"
              :rules="rules.transportType"
              :items="itemsTransportList"
              v-model="form.transportTypesId"
              :filter="pageFilterTransportTypesItem"
              @change="onChangeTransportType"
              @updateItems="updateTransportTypesItem"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="merkTransport">
              {{$_strings.transport.INPUT_LABEL_MERK_TRANSPORT}}
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="merkTransport"
              outlined
              dense
              prop="merkTransport"
              v-model="form.brand"
              :rules="rules.merkTransport"
              :disabled="isEditing"
            > </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="noTransport">
              {{$_strings.transport.TRANSPORT_NUMBER}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-tooltip v-model="showTooltipNoTransport" left>
              <template v-slot:activator="{ }">
                <v-text-field
                  outlined
                  dense
                  prop="noTransport"
                  placeholder="A 1234 BC"
                  :rules="rules.noTransport"
                  :disabled="isEditing"
                  v-model="form.licensePlate"
                > </v-text-field>
              </template>
              <p>Ex: A 1234 BC</p>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="dimension">
              {{$_strings.transport.INPUT_LABEL_DIMENSION}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="dimension"
              outlined
              dense
              readonly
              filled
              prop="dim"
              v-model="form.dimension"
              :rules="rules.dimension"
              :disabled="isEditing"
            > </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="year">
              {{$_strings.transport.INPUT_LABEL_YEAR_TRANSPORT}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="year"
              outlined
              dense
              prop="year"
              type="number"
              :rules="rules.year"
              :disabled="isEditing"
              v-model="form.year"
            > </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="kirExpiredDate">
              {{$_strings.transport.INPUT_LABEL_KIR_EXPIRED}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="pt-0">
            <v-menu
              :close-on-content-click="false"
              v-model="menuKirExpiredDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="kirExpiredDate"
                  dense
                  outlined
                  :value="form.kirExpiredDate && dateFormat(form.kirExpiredDate)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.kirExpiredDate"
                  clearable
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="form.kirExpiredDate"
                @change="menuKirExpiredDate = false"
                no-title
                scrollable
                :min="dayjs().format('YYYY-MM-DD')"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="kirExpiredDate">
              {{$_strings.transport.INPUT_LABEL_STNK_EXPIRED}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="pt-0">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              v-model="menuStnkExpiredDate"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  :value="form.stnkExpiredDate && dateFormat(form.stnkExpiredDate)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.stnkExpiredDate"
                  clearable
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="form.stnkExpiredDate"
                @change="menuStnkExpiredDate = false"
                no-title
                scrollable
                :min="dayjs().format('YYYY-MM-DD')"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="note">
              Catatan
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-textarea
              id="note"
              v-model="form.note"
              no-resize
              outlined
              dense
              clearable
              rows="5"
              :counter="255"
              :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.EXPLANATION_OF_REASON)]"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="driverName">
              {{$_strings.transport.INPUT_LABEL_DRIVER}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <common-auto-complete-items
              id="driverName"
              type="driverList"
              outlined
              dense
              item-value="id"
              item-text="name"
              searchName="name"
              v-model="form.companyDriverId"
              :rules="rules.driverName"
              :items="itemsDriverList"
              :filter="pageFilterDriverList"
              @updateItems="updateDriverListItem"
              :optional="{
                companyId: $route.params.companyId
              }"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-2" cols="12" sm="4" md="2">
            <label for="imageUrl">
              {{$_strings.transport.INPUT_LABEL_IMAGE}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0" cols="12" sm="4">
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card
                v-if="!form.imageUrl"
                height="200"
                class="d-flex justify-center align-center"
                flat
              >
                <p class="font-italic red--text" v-if="formUpload.isError">*{{$_strings.transport.INPUT_LABEL_IMAGE}} wajib di upload*</p>
                <p class="font-italic grey--text" v-else>*Belum upload gambar*</p>
              </v-card>
              <v-img
                v-else
                :src="form.imageUrl"
                contain
                aspect-ratio="1"
                height="200"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-actions class="white">
                <v-btn
                  v-if="!form.imageUrl"
                  color="blue-grey"
                  small
                  block
                  class="pa-4 white--text"
                  @click="$refs['file'].$refs.input.click()"
                  :disabled="!form.transportTypesId"
                >
                  Upload Gambar
                  <v-icon
                    right
                    dark
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deletePhoto"
                  :loading="formUpload.isLoading"
                >
                  Hapus Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-file-input
                class="d-none"
                v-model="formUpload.data"
                @change="($event) => setFileInput($event)"
                ref="file"
                type="file"
                accept="image/*"
                outlined
                dense
              >
              </v-file-input>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              elevation="2"
              color="primary"
              class="float-right "
              @click="submit"
            >
              <b>{{ $_strings.common.SAVE }}</b>
            </v-btn>
            <v-btn
              elevation="2"
              class="mr-2 float-right"
              @click="$router.push('/main/transport/list')"
            >
              <b>{{ $_strings.common.BACK }}</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, rulesNotes } from '../../helper/commonHelpers';

export default {
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
    } else if (this.transportId) {
      this.fetchTransportDetail();
    }
  },
  beforeDestroy() {
    if (this.fileQueueDeletion.length && this.isSubmited) {
      Promise.all(this.fileQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
    }
  },
  data() {
    return {
      menuKirExpiredDate: false,
      menuStnkExpiredDate: false,
      showTooltipNoTransport: false,
      formUpload: {
        isLoading: false,
        data: null,
        base64: null,
        isError: false,
        fileName: '',
        filePath: '',
      },
      form: {
        transportTypesId: null,
        brand: '',
        licensePlate: '',
        dimension: '',
        year: '',
        note: '',
        companyDriverId: null,
        kirExpiredDate: '',
        stnkExpiredDate: '',
        imageUrl: null,
      },
      rules: {
        transportType: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_TYPE_TRANSPORT),
        ],
        noTransport: [
          (value) => {
            const pattern = /^([A-Z]{1,4})([\s]{1})([0-9]{1,5})([\s]{1})([A-Z]{0,4})$/;
            if (!value) {
              this.showTooltipNoTransport = false;
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_LICENCE_PLATE);
            }
            if (!pattern.test(value)) {
              this.showTooltipNoTransport = true;
              return this.$_strings.messages.validation.LICENSE_PLATE;
            }
            this.showTooltipNoTransport = false;
            return pattern.test(value);
          },
        ],
        dimension: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_DIMENSION),
        ],
        year: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_ALERT_YEAR_TRANSPORT),
          (v) => (v || '').length <= 4 || 'Maksimal 4 angka',
        ],
        kirExpiredDate: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_KIR_EXPIRED),
        ],
        stnkExpiredDate: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_STNK_EXPIRED),
        ],
        driverName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_DRIVER),
        ],
        imageUrl: [
          (v) => !!this.form.imageUrl || this.$_strings.messages.validation.REQUIRED(this.$_strings.transport.INPUT_LABEL_IMAGE),
        ],
        transportNote: [
          (v) => (v || '').length <= 255 || 'Maksimal 255 karakter atau kurang'],
        merkTransport: [
          (v) => (v || '').length <= 100 || 'Maksimal 100 karakter atau kurang'],
      },
      itemsDriverList: [],
      itemsTransportList: [],
      // loaders
      isUploading: false,
      fileQueueDeletion: [],
      isSubmited: false,
      pageFilterTransportTypesItem: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      pageFilterDriverList: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  computed: {
    transportId() {
      return this.$route.params.transportId;
    },
    isEditing() {
      return !!this.transportId;
    },
  },
  methods: {
    dayjs,
    rulesNotes,
    dateFormat,
    updateTransportTypesItem(items) {
      this.itemsTransportList = [...this.itemsTransportList, ...items];
    },
    updateDriverListItem(items) {
      this.itemsDriverList = [...this.itemsDriverList, ...items];
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file melebihi maksimum ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setFileInput(file) {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const transportType = this.itemsTransportList.find((tr) => tr.id === this.form.transportTypesId).name.replaceAll(' ', '_');
      const replacePlatNo = this.form.licensePlate.replaceAll(' ', '_');
      const filePath = `transport/${companyId}/${transportType}/${replacePlatNo}/`;
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        this.formUpload = {
          ...this.formUpload,
          base64: base64Image,
          fileName: file.name,
          isError: false,
          filePath,
        };
        this.form.imageUrl = base64Image;
      };
      reader.readAsDataURL(file);
    },
    async deletePhoto() {
      this.formUpload = {
        data: null,
        base64: null,
        isError: false,
        fileName: '',
        filePath: '',
      };
      if (!this.isEditing) {
        this.form.imageUrl = null;
        return;
      }
      this.fileQueueDeletion.push({
        url: this.form.imageUrl,
      });
      this.form.imageUrl = null;
    },
    setData(data) {
      this.form = {
        ...data,
        kirExpiredDate: dayjs(data.kirExpiredDate).format('YYYY-MM-DD'),
        stnkExpiredDate: dayjs(data.stnkExpiredDate).format('YYYY-MM-DD'),
      };
      this.itemsTransportList.push({
        id: data.transportTypesId,
        name: data.transportTypesName,
      });
      this.itemsDriverList.push({
        id: data.companyDriverId,
        name: data.driverName,
      });
    },
    async fetchTransportDetail() {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.transport.transportDetail(this.$route.params.transportId);
        this.setData(result.data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      this.$root.$loading.show();
      if (this.formUpload.base64) {
        const { base64, fileName, filePath } = this.formUpload;
        try {
          const resUpload = await this.$_services.uploads.uploadImage(base64, fileName, filePath);
          this.form.imageUrl = resUpload.data.url;
          if (this.fileQueueDeletion[0]?.url === resUpload.data.url) {
            this.fileQueueDeletion = [];
          }
        } catch (err) {
          this.$root.$loading.hide();
          return;
        }
      } else if (!this.form.imageUrl) {
        this.$dialog.notify.error('Wajib upload gambar');
        this.formUpload.isError = true;
        this.$root.$loading.hide();
        return;
      }
      try {
        const form = {
          ...this.form,
          companyId: this.$route.params.companyId,
        };
        await this.$_services.transport.createEditTransport(form, this.transportId);
        this.isSubmited = true;
        if (this.transportId) {
          this.$dialog.notify.success(this.$_strings.transport.EDIT_MESSAGE_TEXT);
        } else {
          this.$dialog.notify.success(this.$_strings.transport.SUCCESS_MESSAGE_TEXT);
        }
        this.$router.push('/main/transport/list');
      } finally {
        this.$root.$loading.hide();
      }
    },
    onChangeTransportType(val) {
      const temp = this.itemsTransportList.find((el) => el.id === val);
      this.form.dimension = `${temp.length} x ${temp.width} x ${temp.height}`;
    },
  },
  watch: {
    kirDate() {
      if (this.kirDate) {
        this.kirExpiredDate = dayjs(this.kirDate).format('YYYY-MM-DD');
        this.form.kirExpiredDate = dayjs(this.kirExpiredDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        this.kirDateFormat = '';
      }
    },
    stnkDate() {
      if (this.stnkDate) {
        this.stnkExpiredDate = dayjs(this.stnkDate).format('YYYY-MM-DD');
        this.form.stnkExpiredDate = dayjs(this.stnkDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        this.stnkDateFormat = '';
      }
    },
  },
};
</script>
